import '../../styles/pages/fall-winter-2022/fall-winter-2022-index.scss'
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet";
import Link from "gatsby-plugin-transition-link/AniLink";
import {fallWinter2022Stories} from '../../components/fall-winter-2022-stories/stories';
import ReactFullpage from '@fullpage/react-fullpage';
import { Preloader, Placeholder } from 'react-preloading-screen';
import { SwishSpinner } from "react-spinners-kit";
import Icon from "../../components/icons"

import ITAT from '../../images/spring-2020/campaign-logo-horiz-with-tag-white.svg'
import CSUFNews from '../../images/fall-winter-2020/csuf-news.svg'

import ClassNotesHero from '../../images/fall-winter-2022/class-notes-hero.jpg';

import socialBanner from "../../images/fall-winter-2022/social-banners/under-the-big-top.jpg";

const pluginWrapper = () => {
  require('../../scripts/fullpage.responsiveSlides.min.js');
  // require('../../scripts/fullpage.scrollHorizontally.min.js');
};


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerHide: false
    };
  }

  componentWillUnmount() {
    // console.log('unmount')
    // window.fullpage_api.destroy('all');
  }

  render() {
    var pageTitle = 'Titan - The Digital Magazine of Cal State Fullerton';
    var pageDesc = 'TITAN, the signature magazine of Cal State Fullerton.. Here you will find bold and inspiring stories about the university’s people and programs — stories that will keep you connected to your CSUF family, demonstrate the impact of your support and make you feel proud to be a Titan.';
    var slug = 'index-fall-winter-2022';


    const list = fallWinter2022Stories.map(function(story){
      if(story.slug != 'presidents-message') {
        var url = "/fall-winter-2022/" + story.slug + "/";

        var linkStyle = {
          backgroundImage: `url(${story.thumbnailIndex})`
        }

        var subtitle = null;
        if (story.subtitle && story.subtitle != '') {
          subtitle = <span className="subtitle">{story.subtitle}</span>
        }

        // var classname = null;
        // if (story.slug === 'under-the-big-top') {
        //   var classname = 'animated fadeInUp delay-2s';
        // }

        return (
          <section className="section" id={story.slug} key={story.slug} style={linkStyle}>
            <div className={`text ${story.homepageTextPosition}`}>
              <h2>{story.title}</h2>
              {subtitle}
              <Link
              paintDrip
              className="story-button"
              hex="#FF7900"
              duration={.75}
              to={url}>
                Read More <Icon name="arrow-right" alt="Right Arrow" class="xs" />
              </Link>
            </div>
            <div className={`tint ${story.homepageTextPosition}`} />
          </section>
        )
      }
    })

    return (
      <Layout locationInfo={this.props.location} eaderHide={this.state.headerHide} issue="fall-winter-2022">
        <Helmet>
          <body className="fall-winter-2022-index" />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <Preloader fadeDuration={1000} style={{backgroundColor: '#111'}}>
          <h1 style={{display: 'none'}}>Titan: Fall/Winter 2021</h1>

          <ReactFullpage
          id="index-content"
          licenseKey={'74BC1699-BD29452E-B9391E28-1CBD2120'}
          // menu="#menu"
          pluginWrapper={pluginWrapper}
          normalScrollElements={'#mainNav, .text-container'}
          loopBottom={true}
          loopTop={true}
          navigation={true}
          slidesNavigation={false}
          controlArrows={false}
          responsiveSlides={true}
          responsiveSlidesKey={'ZnVsbGVydG9uLmVkdV85MzFjbVZ6Y0c5dWMybDJaVk5zYVdSbGN3PT00Zkk='}
          onLeave ={(origin, destination, direction) => {

            if (direction === 'down') {
              this.setState({headerHide: true});
            } else {
              this.setState({headerHide: false});
            }

            var lastID = origin.item.id;
            var list = document.getElementById(lastID).getElementsByClassName("text");
            for (const element of list) {
              element.classList.remove('fadeInUp');
              element.classList.add('fadeOutUp');
            }

          }}
          afterLoad={(origin, destination, direction) => {

            console.log(destination.item.id);
            var currentID = destination.item.id;

            if (direction != null || currentID === 'under-the-big-top') {
              var list = document.getElementById(currentID).getElementsByClassName("text");
              // console.log(list)
              // list[0].classList.remove('fadeOutUp');
              // list[0].classList.add('animated','fadeInUp');

              for (const element of list) {
                element.classList.remove('fadeOutUp');
                element.classList.add('animated','fadeInUp');
              }
            }

          }}
          responsiveWidth={'769'}
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                {list}
                <section className='section' id="class-notes" style={{backgroundImage: `url(${ClassNotesHero})`}}>
                  <div className='text left'>
                    <h2>Alumni Class Notes</h2>
                    <span className="subtitle">Class Notes is the place to catch up. Let fellow alumni know about career moves, awards and honors, publications and performances, births and marriages, and other life events.</span>
                    <a href="https://alumni.fullerton.edu/class-notes/" target="_blank" className="story-button">Visit Class Notes <Icon name="arrow-right" alt="" class="xs" /></a>
                  </div>
                  <div className='tint left' />
                </section>
                <section className='section' id="end-cta">
                  <div className="split" id="campaign">
                    <div className='text left'>
                      <h2><img src={ITAT} alt="It Takes a Titan - The Campaign for Cal State Fullerton" style={{width: '400px'}} /></h2>
                      <p>The university’s first-ever comprehensive philanthropic campaign aims to elevate the potential of Titan students. The $250 million initiative prioritizes investing in projects that enhance academic innovation, empower students, transform campus structures and enrich the community.</p>
                      <a href="https://campaign.fullerton.edu/" target="_blank" className="story-button">Learn More <Icon name="arrow-right" alt="" class="xs" /></a>
                    </div>
                  </div>
                  <div className="split" id="csuf-news">
                    <div className='text left'>
                      <h2><img src={CSUFNews} alt="CSUF News" style={{width: '250px'}}  /></h2>

                      <ul>
                        <li key="news-0"><a href="https://news.fullerton.edu/2022/10/titans-make-grand-splash-at-new-aquatics-center/" target="_blank" rel="nofollow noreferrer">Titans Make Grand Splash at New Aquatics Center</a></li>
                        <li key="news-1"><a href="https://news.fullerton.edu/2022/10/celebrating-latinx-culture-at-cal-state-fullerton-with-grammy-award-winning-poncho-sanchez/" target="_blank" rel="nofollow noreferrer">Celebrating Latinx Culture at Cal State Fullerton With Grammy Award-Winning Poncho Sanchez</a></li>
                        <li key="news-2"><a href="https://news.fullerton.edu/2022/09/cal-state-fullerton-named-one-of-nations-best-colleges-top-performers-on-social-mobility/" target="_blank" rel="nofollow noreferrer">Cal State Fullerton Named One of Nation’s Best Colleges, Top Performers on Social Mobility</a></li>
                        <li key="news-3"><a href="https://news.fullerton.edu/2022/08/campus-celebrates-new-housing-complex-with-ribbon-cutting-ceremony/" target="_blank" rel="nofollow noreferrer">Campus Celebrates New Housing Complex With Ribbon-Cutting Ceremony</a></li>
                        <li key="news-4"><a href="https://news.fullerton.edu/2022/05/governors-may-revise-includes-67-5-million-for-cal-state-fullertons-engineering-and-computer-science-innovation-hub/" target="_blank" rel="nofollow noreferrer">Governor’s May Revise Includes $67.5 Million for Cal State Fullerton’s Engineering and Computer Science Innovation Hub</a></li>
                      </ul>

                      <p>Stay up to date on all the latest campus news by visiting the <a href="http://news.fullerton.edu/" target="_blank" rel="noreferrer">CSUF News website</a>. Submit story ideas to <a href="mailto:newssubmissions@fullerton.edu">newssubmissions@fullerton.edu</a>.</p>
                    </div>
                  </div>
                </section>
              </ReactFullpage.Wrapper>
            );
          }}
        />

        <Placeholder>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
            <SwishSpinner size={30} frontColor="#00274C" backColor="#FF7900" />
            <span style={{
              fontSize: '.7em',
              display: 'block', 
              marginTop: '30px', 
              color: '#ccc', 
              textTransform: 'uppercase',
              textAlign: 'center',
              letterSpacing: '5px'
            }}>
              Loading
            </span>
          </div>
				</Placeholder>
			</Preloader>

        
      </Layout>
    );
  }
}